<template>
  <div class="archives">
    <div class="archives-con posR">
      <div class="border-top">
        <img
          src="https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_642b1820-9e79-11ec-b125-0bb52bbb7840/16e06acf-d2e5-4a13-9f24-a5e9f6171189.png"
        >
      </div>
      <div class="border-center">
        <div class="center-con">
          <div class="tC posR title">
            工业互联网企业节点档案
            <span class="left" />
            <span class="right" />
          </div>
          <div class="logo">
            <img
              src="https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_642b1820-9e79-11ec-b125-0bb52bbb7840/982c6e94-a17c-4d28-b8a5-fcfab6de89f3.png"
            >
          </div>
          <div class="tC c006aa9 f24">中国工业互联网标识解析服务中心</div>
          <div class="tC c006aa9 f26 fwb mt30">{{ entBasicInfo.orgName }}</div>
          <div class="gongye-info mt30">
            <p>
              <b>公司名称：</b>
              <span>{{ entBasicInfo.orgName }}</span>
            </p>
            <p>
              <b>企业节点：</b>
              <span>{{ companyNode }}</span>
            </p>
            <p>
              <b>注册时间：</b>
              <span>{{ registerTime }}</span>
            </p>
            <p>
              <b>已加入工业互联网：</b>
              <span>{{ userTime }}天</span>
            </p>
            <p>
              <b>年审有效期：</b>
              <span>暂无</span>
            </p>
            <p>
              <b>注册服务机构：</b>
              <span>工业互联网标识解析国家顶级节点--北京</span>
            </p>
          </div>
          <div class="company-info mt30">
            <div class="company-head">
              <span><img
                src="https://gongye.rntd.cn/zhengshu/images/company.png"
              ></span>企业信息
            </div>
            <div class="flex">
              <div class="c-label fwb">中文名称：</div>
              <div>{{ entBasicInfo.orgName }}</div>
            </div>
            <div class="flex">
              <div class="c-label fwb">官方网站：</div>
              <div>{{ entBasicInfo.website || "暂无" }}</div>
            </div>
            <div class="flex">
              <div class="c-label fwb">所属行业：</div>
              <div>{{ entBasicInfo.industryCategory }}</div>
            </div>
            <div class="flex">
              <div class="c-label fwb">中文地址：</div>
              <div>{{ entBasicInfo.orgAddr }}</div>
            </div>
            <div>
              <b class="fwb">{{ entBasicInfo.orgCrtType }}：</b>
              <span>{{ entBasicInfo.orgCrtCode }}</span>
            </div>
            <div class="flex">
              <div class="c-label fwb">注册资本：</div>
              <div>{{ entBasicInfo.regCapital || "0.00" }}(万人民币)</div>
            </div>
            <div>
              <b class="fwb">法定代表人：</b>
              <span>{{ entBasicInfo.legalName }}</span>
            </div>
            <div class="flex">
              <div class="c-label fwb">经营范围：</div>
              <div>{{ entBasicInfo.industrySpecific }}</div>
            </div>
            <div v-if="official.length > 0 || shop.length > 0" class="flex">
              <div class="c-label fwb">赋码站点：</div>
            </div>
            <div v-if="official.length > 0" class="flex">
              <div class="c-label">企业官网：</div>
              <div>
                <div v-for="item in official" :key="item.dapp_id">
                  <a :href="'http://' + item.dapp_domain" target="_blank">{{
                    item.dapp_domain
                  }}</a>
                  {{ "（" + item.dapp_name + "）" }}
                </div>
              </div>
            </div>
            <div v-if="shop.length > 0" class="flex">
              <div class="c-label">网上商城：</div>
              <div>
                <div v-for="item in shop" :key="item.dapp_id">
                  <a v-if="item.dapp_port_type == 1" :href="'http://' + item.dapp_domain + '/mall/'" target="_blank">{{
                    item.dapp_domain
                  }}</a>
                  <a v-else :href="'http://' + item.dapp_domain" target="_blank">{{
                    item.dapp_domain
                  }}</a>
                  {{ "（" + item.dapp_name + "）" }}
                </div>
              </div>
            </div>
          </div>
          <div class="link-box mt30">
            <div>
              <div class="link-ul link-left">
                <h1>数据验证源</h1>
                <ul>
                  <li>
                    <a :href="idfileUrl" target="_blank">企业工业互联网入网许可证</a>
                  </li>
                  <li>
                    <a :href="getUrl" target="_blank">国家工业互联网标识解析</a>
                  </li>
                  <li>
                    <a href="https://www.cdiisp.com/" target="_blank">成都市工业互联网公共服务平台</a>
                  </li>
                  <li>
                    <a :href="websiteManagement" target="_blank">工业互联网标识工网通服务中心</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="logo-box tC mt20">
            <div>
              <img src="../../images/node_white.png">
            </div>
            <div>
              <img src="../../images/mini-logo.png">
            </div>
            <div>
              <img
                src="https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_c433ed20-eb93-11ec-b8b1-33d46d2ddef5/b36023c9-9441-485c-bf0c-216cd3db7a6a.png"
              >
            </div>
          </div>
        </div>
      </div>
      <div class="border-top">
        <img src="https://gongye.rntd.cn/zhengshu/images/border-bottom.png">
      </div>
      <div class="download" @click="download">下载档案</div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/time";
import { companyApi, webSiteApi } from "@/services/api";
import { downloadIamge } from "@/utils/downloadImage";
import config from "@/config/config";
export default {
  data() {
    return {
      official: [],
      shop: []
    };
  },
  computed: {
    entBasicInfo() {
      console.log(this.$store.state);
      return this.$store.state.entBasicInfo;
    },
    companyNode() {
      return this.$store.state.company_node;
    },
    registerTime() {
      return formatDate(this.$store.state.registerTime, "yyyy年MM月dd日");
    },
    userTime() {
      return this.getDays(this.$store.state.registerTime, new Date());
    },
    getUrl() {
      return `https://idfactory.cn/whois/ui/searchResult?identity=${this.$store.state.company_node}`;
    },
    idfileUrl() {
      return `${config.webSiteHttp}certificate?nodeId=${this.$store.state.company_node}`;
    },
    websiteManagement() {
      return config.webSiteHttp;
    }
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      let { code, data } = await webSiteApi.getAll({ name_is_not_empty: 1 });
      if (code == 200 && data) {
        this.official = data.official;
        this.shop = data.shop;
      }
    },
    getDays(date1, date2) {
      date1 = formatDate(date1, "yyyy-MM-dd");
      date2 = formatDate(date2, "yyyy-MM-dd");
      let date1Str = date1.split("-"); // 将日期字符串分隔为数组,数组元素分别为年.月.日
      // 根据年 . 月 . 日的值创建Date对象
      let date1Obj = new Date(date1Str[0], date1Str[1] - 1, date1Str[2]);
      let date2Str = date2.split("-");
      let date2Obj = new Date(date2Str[0], date2Str[1] - 1, date2Str[2]);
      let t1 = date1Obj.getTime();
      let t2 = date2Obj.getTime();
      let dateTime = 1000 * 60 * 60 * 24; // 每一天的毫秒数
      let minusDays = Math.floor((t2 - t1) / dateTime); // 计算出两个日期的天数差
      let days = Math.abs(minusDays); // 取绝对值
      return days;
    },
    async download() {
      let { data } = await companyApi.downloadImag({ type: "record" });
      downloadIamge(data, "数字档案");
    }
  }
};
</script>

<style lang="less" scoped>
.archives {
  padding: 30px 0;
  box-sizing: border-box;
  background: #fdfcfa;
}
.archives-con {
  overflow: hidden;
  width: 1200px;
  margin: 0 auto;
}
.posR {
  position: relative;
}
.border-top {
  margin-bottom: -4px;
}
.border-top img {
  width: 100%;
}
.border-center {
  background: url("https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_642b1820-9e79-11ec-b125-0bb52bbb7840/f829ed7a-baa3-4ab9-a787-abd07cc1c6c3.png")
    repeat-y;
  background-size: 100%;
  overflow-x: hidden;
}
.center-con {
  padding: 0px 120px;
  box-sizing: border-box;
}
.title {
  font-size: 36px;
  color: #006aa9;
  position: relative;
  width: 500px;
  margin: 0 auto;
  text-align: center;
}
.title span {
  display: block;
  width: 150px;
  height: 5px;
  position: absolute;
}
.title span.left {
  background: -moz-linear-gradient(left, #fff, #066eab);
  background: -webkit-linear-gradient(left, #fff, #066eab);
  left: -130px;
  top: 50%;
  margin-top: -3px;
}
.title span.right {
  background: -moz-linear-gradient(left, #066eab, #fff);
  background: -webkit-linear-gradient(left, #066eab, #fff);
  right: -130px;
  top: 50%;
  margin-top: -3px;
}
.logo {
  width: 120px;
  margin: 10px auto;
}
.logo img {
  width: 100%;
}
.f24 {
  font-size: 24px;
}
.c006aa9 {
  color: #006aa9;
}
.tC {
  text-align: center;
}
.mt30 {
  margin-top: 30px;
}
.f26 {
  font-size: 26px;
}
.fwb {
  font-weight: bold;
}
.gongye-info {
  padding: 20px 25px;
  border: 1px solid #eee;
  border-radius: 3px;
  color: #333;
  font-size: 16px;
}
.gongye-info p {
  line-height: 30px;
  padding: 5px 0;
}
.gongye-info p b {
  font-weight: bold;
}
.company-info {
  color: #333;
  font-size: 18px;
}
.company-info > div {
  line-height: 30px;
  padding: 5px 0;
}
.company-head {
  font-size: 20px;
  color: #006aa9;
  font-weight: bold;
}
.company-head span {
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: -5px;
  margin-right: 10px;
}
.company-head span img {
  width: 100%;
  height: 100%;
}
.c-label {
  width: 90px;
  text-align: right;
}
.logo-box {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
  margin-top: 20px;
}
.logo-box > div {
  flex: 1;
  text-align: center;
}
.logo-box > div img {
  width: 120px;
}

.link-ul h1 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  line-height: 40px;
}
.link-ul ul li {
  line-height: 40px;
  list-style: none;
}
.link-ul ul li a {
  color: #4c9ee3;
  text-decoration: none;
  cursor: pointer;
}
.flex a {
  color: #4c9ee3;
  text-decoration: none;
  cursor: pointer;
}
.download {
  width: 96px;
  height: 36px;
  background: #1285d6;
  opacity: 1;
  border-radius: 2px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 36px;
  color: #ffffff;
  text-align: center;
  margin: 25px auto 0;
  cursor: pointer;
}
.flex {
  display: flex;
  // align-items: center;
  justify-content: flex-start;
}
</style>
